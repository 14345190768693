/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FunctionComponent } from "react";

import { StrapiProductFeature, StrapiProductFeatureItem } from "@/types/strapi";

import { BorderRadiuses } from "@/tokens/border";
import { Colors } from "@/tokens/color";
import { ColumnGaps, Spacing } from "@/tokens/spacing";

import { GridColumn } from "@/ui/atoms/grid_column";
import { GridContainer } from "@/ui/atoms/grid_container";
import { Picture } from "@/ui/atoms/picture";
import { Text } from "@/ui/atoms/text";
import { TextHeader } from "@/ui/molecules/text_header";

import { useTypedTheme } from "@/util/hooks/theme_hooks";
import { backgroundBlur, buildStylesByBreakpoint } from "@/util/style_util";
import { getSectionGap } from "@/util/tokens/layout_util";

interface ProductFeatureProps extends StrapiProductFeature {}

export const ProductFeatureStack: FunctionComponent<ProductFeatureProps> = (
    props,
) => {
    /**
     * Globals
     */
    const theme = useTypedTheme();

    /**
     * Styles
     */
    const featureWrapperStyles = (_index: number) =>
        css(
            {
                alignItems: "center",
                borderRadius: BorderRadiuses.borderLarge,
                display: "flex",
            },
            buildStylesByBreakpoint("backgroundColor", {
                extraSmall: Colors[theme.backgrounds.backgroundSecondary],
                medium: "transparent",
            }),
            buildStylesByBreakpoint("flexDirection", {
                extraSmall: "column",
                medium: _index % 2 === 0 ? "row" : "row-reverse",
            }),
            buildStylesByBreakpoint("gap", {
                ...ColumnGaps,
                ...{ extraSmall: Spacing["spacing-8"] },
            }),
            buildStylesByBreakpoint("marginLeft", {
                extraSmall: `calc(-.5 * ${ColumnGaps.extraSmall})`,
                medium: "0",
            }),
            buildStylesByBreakpoint("marginRight", {
                extraSmall: `calc(-.5 * ${ColumnGaps.extraSmall})`,
                medium: "0",
            }),
            buildStylesByBreakpoint("marginTop", {
                extraSmall: Spacing["spacing-8"],
                medium: Spacing["spacing-16"],
            }),
            buildStylesByBreakpoint("padding", {
                extraSmall: `calc(.5 * ${ColumnGaps.extraSmall})`,
                medium: "0",
            }),
        );

    const featureImageContainerStyles = css({
        flex: "1 1 50%",
        position: "relative",
        width: "100%",
    });

    const featureTextContainerStyles = (_index: number) =>
        css(
            {
                alignItems: _index % 2 === 0 ? "center" : "flex-start",
                boxSizing: "border-box",
                display: "flex",
                flex: "1 1 50%",
                flexDirection: "column",
            },
            buildStylesByBreakpoint("gap", {
                extraSmall: Spacing["spacing-1"],
                medium: Spacing["spacing-4"],
            }),
        );

    /**
     * Rendering
     */
    const renderImages = (feature: StrapiProductFeatureItem) => {
        return (
            <div css={featureImageContainerStyles}>
                <Picture {...feature.Demo_Background} aspectRatio="stretch" />

                <div
                    css={css({
                        padding: "5%",
                    })}
                >
                    <Picture
                        pictureClassName={css({
                            backdropFilter: backgroundBlur("blurMedium"),
                            borderRadius: BorderRadiuses.borderLarge,
                        })}
                        {...feature.Demo.Asset}
                    />
                </div>
            </div>
        );
    };

    const renderText = (feature: StrapiProductFeatureItem, _index: number) => {
        const width = (gap: string | undefined) =>
            `calc(4 * ((100vw - 13 * ${gap})/12) + (3 * ${gap}))`;

        const widthStyles = buildStylesByBreakpoint("width", {
            extraSmall: "100%",
            large: width(ColumnGaps.large),
            extraLarge: width(ColumnGaps.extraLarge),
        });
        return (
            <div css={featureTextContainerStyles(_index)}>
                <Text
                    className={widthStyles}
                    fontSize="ProductFeatureHeadline"
                    themeKey="headlinePrimary"
                >
                    {feature.Headline}
                </Text>

                <Text className={widthStyles} fontSize="Subheadline">
                    {feature.Subheadline}
                </Text>
            </div>
        );
    };

    return (
        <GridContainer
            legacyGrid={false}
            marginBottom={getSectionGap(props.Section_Gap)}
        >
            <GridColumn
                columnSpan={{
                    extraSmall: "full",
                    medium: 6,
                    large: 5,
                }}
            >
                <TextHeader {...props.Header} renderAsGrid={false} />
            </GridColumn>

            <GridColumn
                className={css(
                    { display: "flex", flexDirection: "column" },
                    buildStylesByBreakpoint("gap", {
                        extraSmall: Spacing["spacing-8"],
                    }),
                )}
                tag="ul"
            >
                {props.Features.map((feature, _index) => (
                    <li css={featureWrapperStyles(_index)} key={feature.id}>
                        {renderImages(feature)}

                        {renderText(feature, _index)}
                    </li>
                ))}
            </GridColumn>
        </GridContainer>
    );
};
