/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FunctionComponent } from "react";

import { StrapiSpecGroup, StrapiSpecSheet } from "@/types/strapi";
import { ColumnStandardCount } from "@/types/tokens/grid";

import { Colors } from "@/tokens/color";
import { ColumnGaps, Spacing } from "@/tokens/spacing";

import { Divider } from "@/ui/atoms/divider";
import { GridColumn } from "@/ui/atoms/grid_column";
import { GridContainer } from "@/ui/atoms/grid_container";
import { Icon } from "@/ui/atoms/icon";
import { Link } from "@/ui/atoms/link";
import { SubgridContainer } from "@/ui/atoms/subgrid_container";
import { Text } from "@/ui/atoms/text";
import { IconGraphic } from "@/ui/molecules/icon_graphic";
import { TextHeader } from "@/ui/molecules/text_header";

import { useTypedTheme } from "@/util/hooks/theme_hooks";
import {
    buildObjectStylesByBreakpoint,
    buildStylesByBreakpoint,
} from "@/util/style_util";
import { getSectionGap } from "@/util/tokens/layout_util";

export const SpecSheet: FunctionComponent<StrapiSpecSheet> = (props) => {
    /**
     * Variables
     */
    const theme = useTypedTheme();

    /**
     * Styles
     */
    const rowStyles = css(buildStylesByBreakpoint("columnGap", ColumnGaps), {
        display: "flex",
        flexWrap: "wrap",
    });

    const rowItemStyles = css({
        flex: "1 1 0",
    });

    const rowHeadingStyles = css({
        alignItems: "center",
        display: "flex",
    });

    const dividerStyles = css({ flex: "1 0 100%" });

    /**
     * Rendering
     */
    const renderSpecRows = (specification: StrapiSpecGroup) =>
        specification.Spec_Rows.map((detail, _index) => (
            <div css={rowStyles} key={`spec-sheet::list-spec::${_index}`}>
                {!_index && <Divider className={dividerStyles} />}

                <Text
                    className={rowItemStyles}
                    fontFamily="mono"
                    fontSize="TechnicalText"
                    isUppercase={true}
                    labelLineHeight={false}
                    marginBottom="SpecSheetListSpacing"
                    marginTop="SpecSheetListSpacing"
                    tag="h4"
                    themeKey="headlineSecondary"
                >
                    {detail.Title}
                </Text>

                <span css={rowItemStyles}>
                    <Text
                        fontFamily="mono"
                        fontSize="TechnicalText"
                        isUppercase={true}
                        labelLineHeight={false}
                        marginBottom="SpecSheetListSpacing"
                        marginTop="SpecSheetListSpacing"
                        tag="p"
                        themeKey="headlinePrimary"
                    >
                        {detail.Value}
                    </Text>

                    {detail.Link && (
                        <Link
                            className={css({
                                color: Colors[theme.text.textSecondary],
                            })}
                            fontFamily="mono"
                            fontSize="TechnicalText"
                            href={detail.Link.URL}
                            interactionType="underline-appear"
                            isUppercase={true}
                            labelLineHeight={false}
                            marginBottom="SpecSheetListSpacing"
                            renderAsBlock={true}
                            themeKey="linkSecondary"
                        >
                            {detail.Link.Text}
                        </Link>
                    )}
                </span>

                <Divider className={dividerStyles} />
            </div>
        ));

    const renderSpecLists = () =>
        props.Specifications.map((specification, _index) => (
            <GridColumn
                columnSpan={{ extraSmall: "full", large: 3 }}
                columnStart={{
                    extraSmall: 1,
                    large: (_index * 3 + 1) as ColumnStandardCount,
                }}
                key={`spec-sheet::spec-lists::${_index}`}
                marginTop="DefaultInterSectionGap"
                tag="li"
            >
                <Text
                    className={rowHeadingStyles}
                    fontFamily="mono"
                    fontSize="TechnicalText"
                    isUppercase={true}
                    marginBottom="SpecSheetListSpacing"
                    tag="h3"
                >
                    <Icon
                        className={css({
                            marginRight: Spacing["spacing-2"],
                        })}
                        color="purple-40"
                        size={18}
                        slug={specification.Icon}
                    />

                    {specification.Title}
                </Text>

                <ul>{renderSpecRows(specification)}</ul>
            </GridColumn>
        ));

    const renderCta = () => (
        <GridColumn
            className={buildObjectStylesByBreakpoint({
                large: {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                },
            })}
            marginTop="DefaultInterSectionGap"
            tag="li"
        >
            {props.Link_Intro && (
                <Text
                    fontFamily="mono"
                    fontSize="TechnicalText"
                    isUppercase={true}
                    labelLineHeight={false}
                    tag="h3"
                    themeKey="headlineSecondary"
                >
                    {props.Link_Intro}
                </Text>
            )}

            {props.Header.Link && (
                <Link
                    fontFamily="mono"
                    fontSize="TechnicalText"
                    fontWeight="normal"
                    href={props.Header.Link.URL}
                    isUppercase={true}
                    labelLineHeight={false}
                    marginTop="SpecSheetDiveDeeper"
                    themeKey="linkPrimary"
                >
                    {props.Header.Link.Text}
                </Link>
            )}
        </GridColumn>
    );

    const renderSpecTable = () => (
        <SubgridContainer
            columnSpan={{ extraSmall: "full", medium: 6, large: 9 }}
            columnStart={{ extraSmall: 1, medium: 7, large: 4 }}
            tag="ul"
        >
            {renderSpecLists()}

            {renderCta()}
        </SubgridContainer>
    );

    return (
        <GridContainer
            legacyGrid={false}
            marginBottom={getSectionGap(props.Section_Gap)}
        >
            <TextHeader
                {...props.Header}
                eyebrowColor="purple-40"
                headlineSize="HeadlineHero"
                Link={undefined}
            />

            <GridColumn
                columnSpan={{
                    extraSmall: "full",
                    medium: 5,
                    large: 3,
                }}
                marginTop="DefaultInterSectionGap"
            >
                <IconGraphic {...props.Graphic} />
            </GridColumn>

            {renderSpecTable()}
        </GridContainer>
    );
};
