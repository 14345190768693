/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import type { FunctionComponent, ReactNode } from "react";

import {
    SupportedOperatingSystem,
    SupportedOperatingSystemKeys,
} from "@/types/data";

import { BorderRadiuses } from "@/tokens/border";
import { Colors } from "@/tokens/color";
import { TransitionEasings, TransitionSpeeds } from "@/tokens/motion";
import { Spacing } from "@/tokens/spacing";

import { Link } from "@/ui/atoms/link";
import { OperatingSystemIcon } from "@/ui/atoms/operating_system_icon";
import { Text } from "@/ui/atoms/text";

import { useTypedTheme } from "@/util/hooks/theme_hooks";
import { backgroundBlur, buildStylesByBreakpoint } from "@/util/style_util";
import { TrackEvent } from "@/util/tokens/track_event_util";
import { convertToRem } from "@/util/ui_util";

interface OperatingSystemBannerProps {
    className?: SerializedStyles;
    title?: ReactNode;
}

export const OperatingSystemBanner: FunctionComponent<
    OperatingSystemBannerProps
> = (props) => {
    /**
     * Globals
     */
    const theme = useTypedTheme();

    /**
     * Styles
     */
    const containerStyles = css(
        {
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            rowGap: Spacing["spacing-2"],
        },
        /**
         * These are the original gap values, but seem tight. Wait until icons
         * buildStylesByBreakpoint("rowGap", {
         *    extraSmall: Spacing["spacing-1"],
         *    extraLarge: Spacing["spacing-2"],
         * }),
         */

        props.className,
    );

    const osListStyles = css(
        {
            alignItems: "stretch",
            display: "flex",
            flexDirection: "row",
        },
        buildStylesByBreakpoint("columnGap", {
            extraSmall: Spacing["spacing-1"],
            medium: Spacing["spacing-half"],
        }),
    );

    const osListItemLinkStyles = css(
        {
            alignItems: "center",
            background: "transparent",
            borderRadius: BorderRadiuses.borderSmall,
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "row",
            height: "100%",
            textDecoration: "none",
            transition: `background ${TransitionSpeeds.fast}ms ${TransitionEasings.easeInOut}`,
        },
        {
            "&:active": {
                background: Colors["lighten-10"],
            },
            "&:hover": {
                background: Colors["lighten-5"],
            },
            "&:hover, &:active": {
                backdropFilter: backgroundBlur("blurSmall"),
            },
        },
        buildStylesByBreakpoint("columnGap", {
            extraSmall: Spacing["spacing-2"],
            medium: Spacing["spacing-3"],
        }),
        buildStylesByBreakpoint("padding", {
            extraSmall: `${Spacing["spacing-1"]} ${Spacing["spacing-1-half"]}`,
            medium: `${Spacing["spacing-1-half"]} ${Spacing["spacing-2-half"]}`,
        }),
    );

    const osListItemTextStyles = css({
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        rowGap: Spacing["spacing-half"],
    });

    const osIconStyles = buildStylesByBreakpoint("width", {
        extraSmall: convertToRem(24),
        small: convertToRem(28),
        medium: convertToRem(32),
    });

    /**
     * Rendering
     */
    const renderOsIcon = (osName: string) => {
        const formattedOsName =
            osName.toLowerCase() as SupportedOperatingSystem;

        const typedOsName: SupportedOperatingSystem =
            SupportedOperatingSystemKeys.includes(formattedOsName)
                ? formattedOsName
                : "mac";

        return (
            <OperatingSystemIcon
                className={osIconStyles}
                color={theme.ui.uiActive}
                slug={typedOsName}
            />
        );
    };

    const renderOsList = () => {
        const osListItems = ["Mac", "Linux", "Windows"].map((os) => {
            return (
                <li key={`os-banner::os::${os}`}>
                    <Link
                        className={osListItemLinkStyles}
                        href="/download"
                        track={TrackEvent.ALL_DOWNLOADS_LINK}
                    >
                        {renderOsIcon(os)}

                        <div css={osListItemTextStyles}>
                            <Text fontSize="Micro" themeKey="textPrimary">
                                {os}
                            </Text>
                        </div>
                    </Link>
                </li>
            );
        });

        return <ul css={osListStyles}>{osListItems}</ul>;
    };

    return (
        <div css={containerStyles}>
            {props.title}

            {renderOsList()}
        </div>
    );
};
