/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { FunctionComponent } from "react";

import { StrapiMedia } from "@/types/strapi";

import { BorderRadiuses } from "@/tokens/border";
import { ColumnGaps, Spacing } from "@/tokens/spacing";

import { Picture } from "@/ui/atoms/picture";
import { RiveAnimation } from "@/ui/atoms/rive_animation";

import { buildStylesByBreakpoint } from "@/util/style_util";

interface HeroDemoCompactProps {
    backgroundMedia: StrapiMedia;
    className?: SerializedStyles;
    priority?: boolean;
    src: string;
}

export const HeroDemoCompact: FunctionComponent<HeroDemoCompactProps> = ({
    priority = false,
    ...props
}) => {
    /**
     * Styles
     */
    const demoContainerStyles = css(
        {
            borderRadius: BorderRadiuses.borderLarge,
            position: "relative",
        },
        buildStylesByBreakpoint("padding", ColumnGaps),
    );

    const backgroundMediaStyles = css({
        bottom: 0,
        left: 0,
        position: "absolute",
        right: 0,
        top: 0,
    });

    const animationStyles = css(
        {
            zIndex: 10,
        },
        buildStylesByBreakpoint("marginInline", {
            medium: Spacing["spacing-6"],
            large: 0,
        }),
    );

    return (
        <div css={demoContainerStyles}>
            <RiveAnimation
                aspectRatio="16 / 9"
                className={animationStyles}
                priority={priority}
                src={props.src}
                stateMachineName="State Machine 1"
            />

            <Picture
                {...props.backgroundMedia}
                aspectRatio="stretch"
                className={backgroundMediaStyles}
                priority={priority}
                quality={70}
                shouldParallax={true}
                sizeConfiguration="fullWidth"
            />
        </div>
    );
};
