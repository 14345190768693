/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import * as _ from "lodash-es";
import { forwardRef, useImperativeHandle, useMemo, useRef } from "react";

import { TypedRefObject } from "@/types/interactivity";
import { StrapiProductFeature } from "@/types/strapi";

import { BorderRadiuses } from "@/tokens/border";
import { Colors } from "@/tokens/color";
import { TransitionEasings, TransitionSpeeds } from "@/tokens/motion";
import { HalfColumnGaps, Spacing } from "@/tokens/spacing";

import { Link } from "@/ui/atoms/link";
import { Picture } from "@/ui/atoms/picture";
import { ProductFeatureTimer } from "@/ui/atoms/product_feature_timer";
import { Text } from "@/ui/atoms/text";

import { useTypedTheme } from "@/util/hooks/theme_hooks";
import { backgroundBlur, buildStylesByBreakpoint } from "@/util/style_util";

export interface ProductFeaturePanelRef {
    backgroundRef: TypedRefObject;
    detailsRef: TypedRefObject;
    progressBarRef: TypedRefObject;
}

type Feature = StrapiProductFeature["Features"][number];

interface ProductFeaturePanelProps extends Feature {
    className?: SerializedStyles;
    index: number;
    isActive: boolean;
    isTimerEnabled: boolean;
    onClick: () => void;
    onTimerComplete: () => void;
}

export const ProductFeaturePanel = forwardRef<
    ProductFeaturePanelRef,
    ProductFeaturePanelProps
>((props, ref) => {
    /**
     * Globals
     */
    const theme = useTypedTheme();

    /**
     * Refs
     */
    const backgroundRef = useRef(null);
    const detailsRef = useRef(null);
    const progressBarRef = useRef(null);

    useImperativeHandle(
        ref,
        () => ({
            backgroundRef,
            detailsRef,
            progressBarRef,
        }),
        [],
    );

    /**
     * Interactions
     */
    const handleContainerClick = !props.isActive ? props.onClick : undefined;

    /**
     * Styles
     */
    const containerStyles = css(
        {
            cursor: handleContainerClick ? "pointer" : undefined,
            position: "relative",
            willChange: "transform",
            zIndex: 0,
        },
        buildStylesByBreakpoint("paddingBlock", HalfColumnGaps),
        props.className,
    );

    const backgroundStyles = useMemo(() => {
        const offset = _.mapValues(
            HalfColumnGaps,
            (value) => `calc(-1 * ${value})`,
        );

        return css(
            {
                backdropFilter: backgroundBlur("blurMedium"),
                background: Colors[theme.backgrounds.backgroundSecondary],
                borderRadius: BorderRadiuses.borderMedium,
                bottom: 0,
                content: '""',
                opacity: 0,
                pointerEvents: "none",
                position: "absolute",
                top: 0,
                zIndex: -1,
            },
            buildStylesByBreakpoint("left", offset),
            buildStylesByBreakpoint("right", offset),
        );
    }, [theme.backgrounds.backgroundSecondary]);

    const detailsStyles = css({
        height: props.index > 0 ? 0 : undefined,
        overflow: "hidden",
    });

    const headlineStyles = css(
        props.isActive
            ? {
                  color: Colors[theme.text.headlinePrimary],
              }
            : {
                  "&:hover": {
                      color: Colors[theme.text.headlinePrimary],
                  },
                  color: Colors[theme.text.textSecondary],
                  transition: `color ${TransitionSpeeds.fast}ms ${TransitionEasings.easeInOut}`,
              },
    );

    const subheadlineStyles = css({
        marginTop: Spacing["spacing-1"],
    });

    const linkStyles = css(
        {
            display: "inline-block",
        },
        buildStylesByBreakpoint("marginTop", {
            extraSmall: Spacing["spacing-6"],
            medium: Spacing["spacing-8"],
        }),
    );

    const inlineMediaCardStyles = css(
        {
            borderRadius: BorderRadiuses.borderSmall,
            display: "flex",
            marginTop: Spacing["spacing-8"],
            position: "relative",
        },
        buildStylesByBreakpoint("padding", {
            extraSmall: Spacing["spacing-4"],
            small: Spacing["spacing-5"],
        }),
        buildStylesByBreakpoint("display", {
            medium: "none",
        }),
    );

    const inlineMediaBackgroundStyles = css({
        inset: 0,
    });

    /**
     * Rendering
     */
    return (
        <li css={containerStyles} onClick={handleContainerClick}>
            <div css={backgroundStyles} ref={backgroundRef} />

            <ProductFeatureTimer
                enabled={props.isTimerEnabled}
                isActive={props.isActive}
                ref={progressBarRef}
                onComplete={props.onTimerComplete}
            />

            <Text className={headlineStyles} fontWeight="medium" tag="h3">
                {props.Headline}
            </Text>

            <div css={detailsStyles} ref={detailsRef}>
                <Text className={subheadlineStyles}>{props.Subheadline}</Text>

                <figure css={inlineMediaCardStyles}>
                    <Picture
                        {...props.Demo_Background}
                        aspectRatio="stretch"
                        className={inlineMediaBackgroundStyles}
                        shouldParallax={true}
                    />

                    <Picture
                        {...props.Demo.Asset}
                        pictureClassName={css({
                            backdropFilter: backgroundBlur("blurMedium"),
                            height: "auto",
                            position: undefined,
                        })}
                    />
                </figure>

                {props.Link && (
                    <Link className={linkStyles} href={props.Link.URL}>
                        {props.Link.Text}
                    </Link>
                )}
            </div>
        </li>
    );
});

ProductFeaturePanel.displayName = "ProductFeaturePanel";
