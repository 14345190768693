/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FunctionComponent } from "react";

import { StrapiBigTextHero, StrapiMedia } from "@/types/strapi";

import { spacingSets } from "@/tokens/configs/spacing_config";

import { GridColumn } from "@/ui/atoms/grid_column";
import { GridContainer } from "@/ui/atoms/grid_container";
import { SubgridContainer } from "@/ui/atoms/subgrid_container";
import { Text } from "@/ui/atoms/text";
import { CtaGroup } from "@/ui/molecules/cta_group";
import { HeroDemo } from "@/ui/molecules/hero_product_demo";
import { HeroDemoCompact } from "@/ui/molecules/hero_product_demo_compact";
import { OperatingSystemBanner } from "@/ui/molecules/os_banner";
import { RichText } from "@/ui/molecules/rich_text";

import { buildStylesByBreakpoint } from "@/util/style_util";
import { getSectionGap } from "@/util/tokens/layout_util";

import { BillboardCarousel } from "./billboard_carousel";

interface BigTextHeroProps extends StrapiBigTextHero {
    backgroundMedia: StrapiMedia;
}

export const BigTextHero: FunctionComponent<BigTextHeroProps> = (props) => {
    const Show_Os_Banner = props.Show_Os_Banner ?? true;

    const renderSideBySideLayout = () => {
        /**
         * Styles
         */
        const containerStyles = css(
            buildStylesByBreakpoint("alignItems", {
                extraSmall: "start",
                large: "center",
            }),
            buildStylesByBreakpoint(
                "rowGap",
                spacingSets.DefaultPageHeroRowGap,
            ),
        );

        const textContainerStyles = css(
            {
                display: "flex",
                flexDirection: "column",
            },
            buildStylesByBreakpoint("alignItems", {
                extraSmall: "center",
                large: "start",
            }),
            buildStylesByBreakpoint("rowGap", spacingSets.ProductHeroText),
        );

        const subheadlineStyles = css(
            {
                textWrap: "balance",
            },
            buildStylesByBreakpoint("textAlign", {
                extraSmall: "center",
                large: "left",
            }),
        );

        const osBannerStyles = css(
            buildStylesByBreakpoint("justifySelf", {
                large: "start",
            }),
            buildStylesByBreakpoint("marginTop", spacingSets.ProductHeroText),
        );

        /**
         * Rendering
         */
        const renderHeroText = () => {
            return (
                <GridColumn
                    className={textContainerStyles}
                    columnSpan={{
                        extraSmall: "full",
                        medium: 8,
                        large: 5,
                        extraLarge: 4,
                    }}
                    columnStart={{
                        extraSmall: 1,
                        medium: 3,
                        large: 1,
                    }}
                >
                    {props.Eyebrow && (
                        <Text
                            fontSize="HeadlineHeroXl"
                            marginBottom="TextHeaderEyebrow"
                            tag="h1"
                            themeKey="headlineSecondary"
                        >
                            {props.Eyebrow}
                        </Text>
                    )}

                    <Text
                        fontSize="HeadlineHeroXl"
                        tag={props.Eyebrow ? "p" : "h1"}
                        textAlign={{
                            extraSmall: "center",
                            large: "left",
                        }}
                        themeKey="headlinePrimary"
                    >
                        {props.Headline}
                    </Text>

                    <RichText
                        className={subheadlineStyles}
                        config="UiSubheadline"
                    >
                        {props.Subheadline}
                    </RichText>
                </GridColumn>
            );
        };

        const renderCtaGroup = () => {
            return (
                <GridColumn
                    columnSpan={{
                        extraSmall: "full",
                        medium: 6,
                        large: 4,
                    }}
                    columnStart={{
                        extraSmall: 1,
                        medium: 4,
                        large: 1,
                    }}
                    marginTop="ProductHeroCta"
                >
                    <CtaGroup
                        {...props.CTA_Group}
                        alignment={{ extraSmall: "center", large: "start" }}
                    />
                </GridColumn>
            );
        };

        const renderOsBanner = () => {
            return (
                Show_Os_Banner && (
                    <GridColumn className={osBannerStyles}>
                        <OperatingSystemBanner />
                    </GridColumn>
                )
            );
        };

        const renderDemo = () => {
            // TODO(thanik): handle Billboard Carousel

            return (
                <HeroDemoCompact
                    backgroundMedia={props.backgroundMedia}
                    priority={true}
                    src={props.Demo.Asset.url}
                />
            );
        };

        return (
            <GridContainer
                className={containerStyles}
                legacyGrid={false}
                marginBottom={getSectionGap(props.Section_Gap)}
                marginTop="DefaultPageHeroTop"
            >
                <SubgridContainer
                    columnSpan={{
                        extraSmall: "full",
                        large: 5,
                    }}
                >
                    {renderHeroText()}

                    {renderCtaGroup()}

                    {renderOsBanner()}
                </SubgridContainer>

                <GridColumn
                    columnSpan={{
                        extraSmall: "full",
                        large: 7,
                    }}
                    columnStart={{
                        extraSmall: 1,
                        large: 6,
                    }}
                >
                    {renderDemo()}
                </GridColumn>
            </GridContainer>
        );
    };

    const renderStackedLayout = () => {
        /**
         * Styles
         */
        const containerStyles = buildStylesByBreakpoint(
            "rowGap",
            spacingSets.DefaultPageHeroRowGap,
        );

        const subheadlineStyles = buildStylesByBreakpoint(
            "marginTop",
            spacingSets.TextHeaderSubheadline,
        );

        /**
         * Rendering
         */
        const renderHeroText = () => {
            return (
                <SubgridContainer>
                    <GridColumn
                        columnSpan={{
                            extraSmall: "full",
                            medium: 8,
                        }}
                    >
                        {props.Eyebrow && (
                            <Text
                                fontSize="HeadlineHeroXl"
                                marginBottom="TextHeaderEyebrow"
                                tag="h1"
                                themeKey="headlineSecondary"
                            >
                                {props.Eyebrow}
                            </Text>
                        )}

                        <Text
                            fontSize="HeadlineHeroXl"
                            tag={props.Eyebrow ? "p" : "h1"}
                            themeKey="headlinePrimary"
                        >
                            {props.Headline}
                        </Text>
                    </GridColumn>

                    <GridColumn
                        columnSpan={{
                            extraSmall: "full",
                            medium: 7,
                            large: 5,
                        }}
                    >
                        <RichText
                            className={subheadlineStyles}
                            config="UiSubheadline"
                        >
                            {props.Subheadline}
                        </RichText>
                    </GridColumn>
                </SubgridContainer>
            );
        };

        const renderDemo = () => {
            if (props.Demo_Labels && props.Demo_Labels.length > 0) {
                return (
                    <BillboardCarousel
                        backgroundMedia={props.backgroundMedia}
                        demo={props.Demo}
                        demoLabels={props.Demo_Labels!}
                        id={props.id}
                        isHero={true}
                    />
                );
            }
            return (
                <HeroDemo
                    backgroundMedia={props.backgroundMedia}
                    priority={true}
                    src={props.Demo.Asset.url}
                />
            );
        };

        return (
            <GridContainer
                className={containerStyles}
                legacyGrid={false}
                marginBottom={getSectionGap(props.Section_Gap)}
                marginTop="DefaultPageHeroTop"
            >
                {renderHeroText()}

                {props.CTA_Group && (
                    <GridColumn>
                        <CtaGroup {...props.CTA_Group} alignment="start" />
                    </GridColumn>
                )}

                {renderDemo()}
            </GridContainer>
        );
    };

    switch (props.Layout) {
        case "side-by-side":
            return renderSideBySideLayout();
        default:
            return renderStackedLayout();
    }
};
