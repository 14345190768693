/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useRef, useState } from "react";

import { StrapiProductFeature } from "@/types/strapi";

import { Spacing } from "@/tokens/spacing";

import { Picture } from "@/ui/atoms/picture";

import { useMediaPanningAnimation } from "@/util/animation_hooks/media_animations";
import { useDesktopMediaCardExpansionAnimation } from "@/util/animation_hooks/product_feature_animations";
import { useIsomorphicLayoutEffect } from "@/util/hooks/effect_hooks";
import { getCardTransform } from "@/util/product_feature_media_card_util";
import { backgroundBlur, buildStylesByBreakpoint } from "@/util/style_util";

interface ProductFeatureMediaCardProps {
    feature: StrapiProductFeature["Features"][number];
    offset: number;
    total: number;
}

export function ProductFeatureMediaCard(props: ProductFeatureMediaCardProps) {
    /**
     * Globals
     */
    const { feature, offset, total } = props;

    /**
     * Refs
     */
    const cardRef = useRef<HTMLDivElement>(null);
    const assetRef = useRef(null);

    /**
     * State
     */
    const [cardHeight, setCardHeight] = useState(0);

    /**
     * Animations
     */
    useIsomorphicLayoutEffect(() => {
        /**
         * Effect to update card height
         */
        if (cardRef.current) {
            setCardHeight(cardRef.current.offsetHeight);
        }
    }, [cardRef.current?.offsetHeight]);

    useDesktopMediaCardExpansionAnimation(cardRef, cardHeight, offset, total);

    useMediaPanningAnimation(cardRef, assetRef);

    /**
     * Styles
     */
    const cardStyles = css(
        {
            alignItems: "center",
            display: "grid",
            gridColumn: "1",
            gridRow: "1",
            position: "relative",
            transform: getCardTransform(cardHeight, offset, 14),
            transformOrigin: "top center",
            zIndex: total - offset,
        },
        buildStylesByBreakpoint("paddingInline", {
            medium: Spacing["spacing-6"],
            large: Spacing["spacing-8"],
            extraLarge: Spacing["spacing-10"],
        }),
    );

    const backgroundPictureStyles = css({
        inset: 0,
        position: "absolute",
    });

    return (
        <figure css={cardStyles} ref={cardRef}>
            <Picture
                {...feature.Demo_Background}
                aspectRatio="stretch"
                className={backgroundPictureStyles}
                quality={70}
                shouldParallax={true}
            />

            <div
                css={css({
                    zIndex: 10,
                })}
                ref={assetRef}
            >
                <Picture
                    {...feature.Demo.Asset}
                    pictureClassName={css({
                        backdropFilter: backgroundBlur("blurMedium"),
                    })}
                />
            </div>
        </figure>
    );
}
