/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FunctionComponent, useRef, useState } from "react";

import { StrapiBillboardCarousel } from "@/types/strapi";

import { BorderRadiuses } from "@/tokens/border";
import { Colors } from "@/tokens/color";
import { spacingSets } from "@/tokens/configs/spacing_config";
import { ColumnGaps, Spacing } from "@/tokens/spacing";

import { GridColumn } from "@/ui/atoms/grid_column";
import { GridContainer } from "@/ui/atoms/grid_container";
import { Media } from "@/ui/atoms/media";
import { RiveAnimation } from "@/ui/atoms/rive_animation";
import { SubgridContainer } from "@/ui/atoms/subgrid_container";
import { Text } from "@/ui/atoms/text";
import { VerticalDividers } from "@/ui/molecules/vertical_dividers";

import { useBillboardCarouselParallaxAnimation } from "@/util/animation_hooks/billboard_animations";
import {
    useBillboardScrollToAnimation,
    useSubheadlineAnimation,
} from "@/util/animation_hooks/homepage_animations";
import { useTypedTheme } from "@/util/hooks/theme_hooks";
import { buildStylesByBreakpoint } from "@/util/style_util";
import { getSectionGap } from "@/util/tokens/layout_util";
import { convertToRem } from "@/util/ui_util";

type PascalCaseProps = {
    Background_Media: StrapiBillboardCarousel["Background_Media"];
    backgroundMedia?: never;
    Demo: StrapiBillboardCarousel["Demo"];
    demo?: never;
    Demo_Labels: StrapiBillboardCarousel["Demo_Labels"];
    demoLabels?: never;
    Title: StrapiBillboardCarousel["Title"];
};

type CamelCaseProps = {
    Background_Media?: never;
    backgroundMedia: StrapiBillboardCarousel["Background_Media"];
    Demo?: never;
    demo: StrapiBillboardCarousel["Demo"];
    Demo_Labels?: never;
    demoLabels: StrapiBillboardCarousel["Demo_Labels"];
    Title?: never;
    title?: StrapiBillboardCarousel["Title"];
};

interface BillboardCarouselProps
    extends Omit<
        StrapiBillboardCarousel,
        "Background_Media" | "Demo_Labels" | "Demo" | "Title"
    > {
    isHero?: boolean;
}

type BillboardCarouselPropsWithCasing = BillboardCarouselProps &
    (PascalCaseProps | CamelCaseProps);

export const BillboardCarousel: FunctionComponent<
    BillboardCarouselPropsWithCasing
> = (props) => {
    /**
     * Theme
     */
    const theme = useTypedTheme();

    /**
     * Refs
     */
    const demoRef = useRef<HTMLElement>(null);
    const textRef = useRef(null);
    const titleRef = useRef(null);
    const demoContainerRef = useRef(null);
    const containerRef = useRef(null);

    /**
     * State Machine
     */
    const [currentSlide, setCurrentSlide] = useState(0);

    /**
     * Hooks
     */
    useBillboardScrollToAnimation(demoRef, currentSlide);

    useSubheadlineAnimation(
        textRef,
        (props.Demo_Labels || props.demoLabels)?.length,
        currentSlide,
    );

    useBillboardCarouselParallaxAnimation(
        containerRef,
        titleRef,
        demoContainerRef,
    );

    /**
     * Styles
     */
    const containerStyles = buildStylesByBreakpoint("rowGap", {
        extraSmall: Spacing["spacing-0"],
        small: Spacing["spacing-0"],
        medium: Spacing["spacing-12"],
        large: Spacing["spacing-18"],
    });

    const titleStyles = css(
        {
            background: `linear-gradient(93.74deg, ${Colors["lighten-30"]} 0%, ${Colors["lighten-5"]} 106.92%), linear-gradient(0deg, ${Colors["yellow-80"]}, ${Colors["yellow-80"]}), ${Colors["lighten-50"]}`,
            backgroundClip: "text",
            letterSpacing: "-0.04em !important",
            textFillColor: "transparent",
            zIndex: 1,
        },
        /**
         * Importants are used to override the complex typography
         * utility library. Absolutely fine for this scenario.
         */
        buildStylesByBreakpoint("fontSize", {
            extraSmall: "26vw !important",
            small: "30vw !important",
            medium: "23.5vw !important",
            extraLarge: `${convertToRem(400)} !important`,
        }),
        buildStylesByBreakpoint("lineHeight", {
            extraSmall: ".95 !important",
            medium: ".85 !important",
        }),
    );

    const demoTitlesListStyles = css(
        buildStylesByBreakpoint("gap", ColumnGaps),
        buildStylesByBreakpoint(
            "paddingTop",
            spacingSets["BillboardScrollHeadline"],
        ),
        buildStylesByBreakpoint(
            "paddingBottom",
            spacingSets["BillboardScrollHeadline"],
        ),
        {
            display: "flex",
            msOverflowStyle: "none",
            overflowX: "auto",
            overscrollBehaviorX: "contain",
            scrollbarWidth: "none",
            scrollSnapType: "x mandatory",
            width: "100%",
        },
        {
            "&::-webkit-scrollbar": {
                display: "none",
            },
        },
    );

    const demoListItemStyles = (_index: number) => {
        let margin = 0;
        if (demoRef.current) {
            const containerWidth = demoRef.current.clientWidth;
            const childWidth = demoRef.current.children[_index].clientWidth;
            margin = 0.5 * (containerWidth - childWidth);
        }

        return css(
            buildStylesByBreakpoint("flex", {
                extraSmall: "0 0 100%",
                medium: "1 1 auto",
            }),
            buildStylesByBreakpoint("marginLeft", {
                extraSmall: "0",
                medium: _index === 0 ? `${margin}px` : 0,
            }),
            buildStylesByBreakpoint("marginRight", {
                extraSmall: "0",
                medium:
                    _index ===
                    (props.Demo_Labels || props.demoLabels)?.length - 1
                        ? `${margin}px`
                        : 0,
            }),
            {
                display: "flex",
                justifyContent: "center",
                scrollSnapAlign: "center",
                scrollSnapStop: "always",
            },
            buildStylesByBreakpoint("alignItems", {
                extraSmall: "center",
                medium: "normal",
            }),
        );
    };

    const demoTitleNumberStyles = css({
        alignItems: "center",
        background: Colors[theme.backgrounds.backgroundSecondary],
        borderRadius: BorderRadiuses.borderRound,
        display: "flex",
        height: "2em",
        justifyContent: "center",
        marginRight: Spacing["spacing-2"],
        width: "2em",
    });

    const demoTitleStyles = css({
        cursor: "pointer",
        whiteSpace: "nowrap",
    });

    const terminalWindowStyles = css(
        buildStylesByBreakpoint("marginLeft", {
            extraSmall: ColumnGaps.extraSmall,
            medium: 0,
        }),
        buildStylesByBreakpoint("marginRight", {
            extraSmall: ColumnGaps.extraSmall,
            medium: 0,
        }),
    );

    const backgroundImageStyles = css(
        {
            height: "100%",
            position: "absolute",
            width: "100%",
            zIndex: -1,
        },
        {
            "&::after": {
                background: `linear-gradient(180deg, ${Colors["darken-30"]} 3.62%, ${Colors["transparent"]} 57.86%), linear-gradient(180deg, ${Colors["transparent"]} 42.14%, ${Colors["darken-50"]} 96.38%), ${Colors["transparent"]}`,
                bottom: 0,
                content: '""',
                left: 0,
                position: "absolute",
                right: 0,
                top: 0,
                zIndex: 1,
            },
        },
    );

    const subheadlineTextStyles = css(
        {
            maxWidth: "45ch",
        },
        buildStylesByBreakpoint("marginInline", {
            extraSmall: Spacing["spacing-4"],
            small: Spacing["spacing-5"],
            medium: Spacing["spacing-6"],
            large: Spacing["spacing-8"],
        }),
    );

    /**
     * Rendering
     */
    const titleWithOffset = () => {
        return (
            <Text
                className={titleStyles}
                columnSpan="full"
                columnStart={1}
                ref={titleRef}
                tag="h2"
            >
                {props.Title?.split(" ").map((_word, _index) => (
                    <span
                        css={css({
                            textAlign: _index % 2 ? "end" : "start",
                        })}
                        key={`billboard-carousel-title-${_index}`}
                    >
                        {_word}
                    </span>
                ))}
            </Text>
        );
    };

    const titleScrollList = () => (
        <GridColumn className={demoTitlesListStyles} ref={demoRef} tag="ul">
            {(props.Demo_Labels || props.demoLabels)?.map((_demo, _index) => (
                <li
                    css={demoListItemStyles(_index)}
                    key={`billboard-carousel-headline-${_index}`}
                >
                    <Text
                        className={demoTitleNumberStyles}
                        fontFamily="mono"
                        fontSize="BillboardHeadlineCarouselNumber"
                        tag="span"
                    >
                        {_index + 1}
                    </Text>

                    <Text
                        className={demoTitleStyles}
                        color={
                            _index === currentSlide
                                ? theme.text.textEmphasis
                                : theme.text.textSecondary
                        }
                        fontSize="Headline2"
                        tag="h2"
                        textAlign="center"
                        onClick={() => setCurrentSlide(_index)}
                    >
                        {_demo.Headline}
                    </Text>
                </li>
            ))}
        </GridColumn>
    );

    const terminalWindow = () => (
        <GridColumn
            className={terminalWindowStyles}
            columnSpan={{
                extraSmall: "full",
                medium: 10,
                large: 8,
            }}
            columnStart={{
                extraSmall: 1,
                medium: 2,
                large: 3,
            }}
        >
            <RiveAnimation
                aspectRatio="16 / 9"
                currentSlide={currentSlide}
                setCurrentSlide={(slidenumber) => {
                    setCurrentSlide(slidenumber);
                }}
                src={(props.Demo || props.demo)?.Asset.url}
                stateMachineName="Agent_Mode_State_Machine"
                triggers={["Trigger 1", "Trigger 2", "Trigger 3"]}
            />
        </GridColumn>
    );

    const demoCarousel = () => {
        return (
            <>
                <Media
                    className={backgroundImageStyles}
                    id={props.id}
                    Media={props.Background_Media || props.backgroundMedia}
                    quality={70}
                />

                {titleScrollList()}

                {terminalWindow()}

                <GridColumn
                    className={css({
                        display: "flex",
                        overflow: "hidden",
                    })}
                    columnSpan="full"
                    columnStart={1}
                    ref={textRef}
                    tag="ul"
                >
                    {(props.Demo_Labels || props.demoLabels)?.map(
                        (_demo, _index) => (
                            <li
                                css={css({
                                    alignItems: "center",
                                    display: "flex",
                                    flex: "0 0 100%",
                                    justifyContent: "space-around",
                                })}
                                key={`billboard-carousel-subheadline-${_index}`}
                            >
                                <Text
                                    className={subheadlineTextStyles}
                                    fontSize="Subheadline"
                                    marginBottom="BillboardSubheadline"
                                    marginTop="BillboardSubheadline"
                                    shouldBalanceWrap={true}
                                    textAlign="center"
                                >
                                    {_demo.Subheadline}
                                </Text>
                            </li>
                        ),
                    )}
                </GridColumn>
            </>
        );
    };

    if (props.isHero) {
        return (
            <SubgridContainer
                className={css({ position: "relative", zIndex: 1 })}
                ref={demoContainerRef}
            >
                {demoCarousel()}
            </SubgridContainer>
        );
    }

    return (
        <VerticalDividers>
            <GridContainer
                className={containerStyles}
                legacyGrid={false}
                marginBottom={getSectionGap(props.Section_Gap)}
                ref={containerRef}
            >
                {titleWithOffset()}

                <SubgridContainer
                    className={css({ position: "relative", zIndex: 1 })}
                    ref={demoContainerRef}
                >
                    {demoCarousel()}
                </SubgridContainer>
            </GridContainer>
        </VerticalDividers>
    );
};
