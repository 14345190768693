/** @jsxImportSource @emotion/react */
import { FunctionComponent } from "react";

import { StrapiProductFeature } from "@/types/strapi";

import { ProductFeatureCarousel } from "./product_feature_carousel";
import { ProductFeatureStack } from "./product_feature_stack";

interface ProductFeatureProps extends StrapiProductFeature {}

export const ProductFeature: FunctionComponent<ProductFeatureProps> = (
    props,
) => {
    switch (props.Display) {
        case "Stack":
            return <ProductFeatureStack {...props} />;
        case "Carousel":
            return <ProductFeatureCarousel {...props} />;
        default:
            return <ProductFeatureCarousel {...props} />;
    }
};
