/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import gsap from "gsap";
import * as _ from "lodash-es";
import dynamic from "next/dynamic";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import ReactPlayerType from "react-player";

import { BorderRadiuses } from "@/tokens/border";

import { GridColumn } from "@/ui/atoms/grid_column";
import { GridContainer, GridContainerRef } from "@/ui/atoms/grid_container";

import { getBreakpointFromMediaMatch } from "@/util/ui_util";

/**
 * We do use a dynamic import for react-player to speed up initial
 * page load while also preventing suspense boundary issues.
 */
const ReactPlayer = dynamic(() => import("react-player/youtube"), {
    ssr: false,
});

interface VideoBlockProps {
    className?: SerializedStyles;
}

export const VideoBlock: FunctionComponent<VideoBlockProps> = (props) => {
    /**
     * Refs
     */
    const containerRef = useRef<GridContainerRef>(null);
    const videoRef = useRef<ReactPlayerType>(null);

    /**
     * State
     */
    const [isVideoMounted, setIsVideoMounted] = useState(false);
    const [isValidBreakpoint, setIsValidBreakpoint] = useState(false);
    const [isInViewport, setIsInViewport] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);

    /**
     * Interaction
     */
    useEffect(() => {
        const determineIsValidBreakpoint = () => {
            setIsValidBreakpoint(
                ["extraSmall", "small"].includes(getBreakpointFromMediaMatch()),
            );
        };

        determineIsValidBreakpoint();

        const _debouncedCalculateLargestValues = _.debounce(
            determineIsValidBreakpoint,
            150,
        );

        window.addEventListener("resize", () => {
            _debouncedCalculateLargestValues();
        });
    }, []);

    useEffect(() => {
        if (containerRef.current?.containerRef.current) {
            const timeline = gsap.timeline({
                scrollTrigger: {
                    onEnter: () => {
                        setIsInViewport(true);
                    },
                    onEnterBack: () => {
                        setIsInViewport(true);
                    },
                    onLeave: () => {
                        setIsInViewport(false);
                    },
                    onLeaveBack: () => {
                        setIsInViewport(false);
                    },
                    trigger: containerRef.current?.containerRef.current,
                },
            });

            return () => {
                timeline.kill();
            };
        }
    }, [isValidBreakpoint]);

    useEffect(() => {
        if (isVideoMounted) {
            if (isInViewport && isValidBreakpoint) {
                setIsPlaying(true);
            } else {
                setIsPlaying(false);
            }
        }
    }, [isVideoMounted, isInViewport, isValidBreakpoint]);

    /**
     * Styles
     */
    const videoContainerStyles = css({
        aspectRatio: "16 / 9",
        borderRadius: BorderRadiuses.borderMedium,
        overflow: "hidden",
    });

    /**
     * Rendering
     */
    return (
        <GridContainer
            containerClassName={props.className}
            legacyGrid={false}
            ref={containerRef}
        >
            <GridColumn
                className={videoContainerStyles}
                columnSpan={{ extraSmall: "full", small: 4 }}
                columnStart={{
                    extraSmall: 1,
                    small: 2,
                }}
            >
                <ReactPlayer
                    controls
                    height="100%"
                    muted={true}
                    pip={true}
                    playing={isPlaying}
                    playsinline={true}
                    ref={videoRef}
                    url="https://www.youtube.com/watch?v=34INSNevPOk"
                    width="100%"
                    onReady={() => {
                        setIsVideoMounted(true);
                    }}
                />
            </GridColumn>
        </GridContainer>
    );
};
