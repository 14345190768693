import gsap from "gsap";
import * as _ from "lodash-es";
import { useEffect, useState } from "react";

import { TypedRefObject } from "@/types/interactivity";

import { GridContainerRef } from "@/ui/atoms/grid_container";

import { useIsomorphicLayoutEffect } from "@/util/hooks/effect_hooks";
import { getBreakpointFromMediaMatch } from "@/util/ui_util";

export function useBillboardCarouselParallaxAnimation(
    containerRef: TypedRefObject<GridContainerRef>,
    titleRef: TypedRefObject,
    demoContainerRef: TypedRefObject,
) {
    /**
     * State
     */
    const [isValidBreakpoint, setIsValidBreakpoint] = useState<boolean>();

    /**
     * Effects
     */
    useIsomorphicLayoutEffect(() => {
        /**
         * We only do parallax on M, L, and XL devices
         */
        const determineIsValidBreakpoint = () => {
            setIsValidBreakpoint(
                ["medium", "large", "extraLarge"].includes(
                    getBreakpointFromMediaMatch(),
                ),
            );
        };

        determineIsValidBreakpoint();

        const _debouncedCalculateLargestValues = _.debounce(
            determineIsValidBreakpoint,
            150,
        );

        window.addEventListener("resize", () => {
            _debouncedCalculateLargestValues();
        });
    }, []);

    useEffect(() => {
        if (typeof isValidBreakpoint !== "undefined") {
            const container = containerRef.current?.containerRef.current;
            const title = titleRef.current;
            const demoContainer = demoContainerRef.current;

            if (demoContainer && title && container) {
                if (isValidBreakpoint) {
                    const yPercentage = 15;

                    const timeline = gsap
                        .timeline({
                            scrollTrigger: {
                                end: "bottom center",
                                scrub: true,
                                start: "top center",
                                trigger: container,
                            },
                        })
                        .to(title, {
                            ease: "none",
                            yPercent: yPercentage,
                        })
                        .to(
                            demoContainer,
                            {
                                ease: "none",
                                yPercent: yPercentage * -1,
                            },
                            "<",
                        );

                    return () => {
                        timeline.kill();
                    };
                } else {
                    gsap.to([title, demoContainer], {
                        duration: 0,
                        yPercent: 0,
                    });
                }
            }
        }
    }, [containerRef, titleRef, demoContainerRef, isValidBreakpoint]);
}
