/** @jsxImportSource @emotion/react */
import type {
    StrapiHomePage,
    StrapiHomePageExperiment,
    StrapiProductPage,
} from "@/types/strapi";

import { AmbientList } from "@/ui/organisms/ambient_list";
import { BigTextHero } from "@/ui/organisms/big_text_hero";
import { BillboardCarousel } from "@/ui/organisms/billboard_carousel";
import { ClassicTestimonial } from "@/ui/organisms/classic_testimonial";
import { CustomerLogoReel } from "@/ui/organisms/customer_logo_reel";
import { PrivacyList } from "@/ui/organisms/privacy_list";
import { ProductFeature } from "@/ui/organisms/product_feature";
import { SpecSheet } from "@/ui/organisms/spec_sheet";
import { TestimonialMosaic } from "@/ui/organisms/testimonial_mosaic";
import { VideoBlock } from "@/ui/organisms/video_block";

import { buildStylesByBreakpoint } from "@/util/style_util";
import { getSectionGap } from "@/util/tokens/layout_util";

type Props = StrapiHomePage | StrapiHomePageExperiment | StrapiProductPage;

export function HomePageTemplate(props: Props) {
    /**
     * Styles
     */
    const videoBlockStyles = buildStylesByBreakpoint("display", {
        extraSmall: "flex",
        medium: "none",
    });

    /**
     * Elements
     */
    const renderPageBody = () => {
        return props.Page_Body.map((pageBodyItem, index) => {
            const key = `home-page::body-item::${pageBodyItem.__component}-${index}`;

            switch (pageBodyItem.__component) {
                case "sections.section-ambient-list":
                    return <AmbientList {...pageBodyItem} key={key} />;
                case "sections.section-billboard-carousel":
                    return <BillboardCarousel {...pageBodyItem} key={key} />;
                case "sections.section-customer-logo-reel":
                    return <CustomerLogoReel {...pageBodyItem} key={key} />;
                case "sections.section-mosaic":
                    return <TestimonialMosaic {...pageBodyItem} key={key} />;
                case "sections.section-privacy":
                    return <PrivacyList {...pageBodyItem} key={key} />;
                case "sections.section-product-feature":
                    return <ProductFeature {...pageBodyItem} key={key} />;
                case "sections.section-spec-sheet":
                    return <SpecSheet {...pageBodyItem} key={key} />;
                case "sections.section-testimonial":
                    return (
                        <ClassicTestimonial
                            {...pageBodyItem}
                            key={key}
                            sectionGap={getSectionGap(pageBodyItem.Section_Gap)}
                        />
                    );
                default:
                    return null;
            }
        });
    };

    return (
        <>
            <BigTextHero
                {...props.Hero}
                backgroundMedia={props.Theme?.Hero_Media}
            />

            {/* Inline video for XS + S breakpoints */}
            {props.Show_Demo_Video && (
                <VideoBlock className={videoBlockStyles} />
            )}

            {renderPageBody()}
        </>
    );
}
